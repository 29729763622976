import store from '@/state/store'

export default [
  {
    path: '/',
    name: 'home',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/home'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./views/account/login'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters.isAuthenticated) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: () => import('./views/account/change-password')
  },
  {
    path: '/parceiro',
    name: 'parceiro',
    component: () => import('./views/account/partner')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('./views/account/register'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/forgot-password',
    name: 'Forgot password',
    component: () => import('./views/account/forgot-password'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters['auth/loggedIn']) {
          // Redirect to the home page instead
          next({ name: 'home' })
        } else {
          // Continue to the login page
          next()
        }
      },
    },
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        store.dispatch('AUTH_LOGOUT')
        const authRequiredOnPreviousRoute = routeFrom.matched.some(
          (route) => route.push('/login')
        )
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
      },
    },
  },
  {
    path: '/404',
    name: '404',
    component: require('./views/utility/404').default,
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: '*',
    redirect: '404',
  },
  {
    path: '/products',
    name: 'products',
    meta: { authRequired: true },
    component: () => import('./views/products/Products'),
  },
  {
    path: '/products/detail/:id?', 
    name: 'product',
    meta: { authRequired: true },
    component: () => import('./views/products/Product')
  },
  {
    path: '/store/products',
    name: 'products-store',
    meta: { authRequired: true },
    component: () => import('./views/products/ProductsStore'),
  },
  {
    path: '/products-store/:store',
    name: 'products-store-admin',
    meta: { authRequired: true },
    component: () => import('./views/products/ProductsStoreAdmin'),
  },
  {
    path: '/store/products/detail/:id?',
    name: 'product-store',
    meta: { authRequired: true },
    component: () => import('./views/products/ProductStore'),
  },
  {
    path: '/store/sales',
    name: 'sales-store',
    meta: { authRequired: true },
    component: () => import('./views/sales/Sales'),
  },
  {
    path: '/store/sales/detail/:id',
    name: 'sale',
    meta: { authRequired: true },
    component: () => import('./views/sales/Sale'),
  },
  {
    path: '/stores',
    name: 'stores',
    meta: { authRequired: true },
    component: () => import('./views/stores/Stores')
  },
  {
    path: '/settings',
    name: 'settings',
    meta: { authRequired: true },
    component: () => import('./views/settings/Settings')
  },
  {
    path: '/categories',
    name: 'categories',
    meta: { authRequired: true },
    component: () => import('./views/products/tables/Categories')
  },
  {
    path: '/brands',
    name: 'brands',
    meta: { authRequired: true },
    component: () => import('./views/products/tables/Brands')
  },
  {
    path: '/features',
    name: 'features',
    meta: { authRequired: true },
    component: () => import('./views/products/tables/Features')
  },
  {
    path: '/tags',
    name: 'tags',
    meta: { authRequired: true },
    component: () => import('./views/products/tables/Tags')
  },
  {
    path: '/store/settings',
    name: 'store/settings',
    meta: { authRequired: true },
    component: () => import('./views/settings/Store')
  },
  {
    path: '/store/shipping',
    name: 'store/shipping',
    meta: { authRequired: true },
    component: () => import('./views/settings/Shipping')
  },
  {
    path: '/store/working-hours',
    name: 'store/working-hours',
    meta: { authRequired: true },
    component: () => import('./views/settings/WorkingHours')
  },
  {
    path: '/store/bank',
    name: 'store/bank',
    meta: { authRequired: true },
    component: () => import('./views/settings/Bank')
  },
  {
    path: '/store/import-products',
    name: 'store/import-products',
    meta: { authRequired: true },
    component: () => import('./views/products/ImportProductsStore.vue')
  },
  {
    path: '/users',
    name: 'users',
    meta: { authRequired: true },
    component: () => import('./views/users/Users')
  },
  {
    path: '/notifications',
    name: 'notifications',
    meta: { authRequired: true },
    component: () => import('./views/notifications/Notifications')
  },
  {
    path: '/schedule-notifications',
    name: 'schedule-notifications',
    meta: { authRequired: true },
    component: () => import('./views/notifications/ScheduleNotifications')
  },
  {
    path: '/coupons',
    name: 'coupons',
    meta: { authRequired: true },
    component: () => import('./views/coupons/Coupons')
  },
  {
    path: '/drivers',
    name: 'drivers',
    meta: { authRequired: true },
    component: () => import('./views/drivers/Drivers')
  },
  {
    path: '/chat/:contactId?',
    name: 'chat',
    meta: { authRequired: true },
    component: () => import('./views/chat/Chat')
  },

  {
    path: '/sales',
    name: 'sales',
    meta: { authRequired: true },
    component: () => import('./views/sales/Sales'),
  },
]
