
import Vue from 'vue'
import Vuex from 'vuex'
import UserService from "@/services/user";

// import modules from './modules'

Vue.use(Vuex)

const store = new Vuex.Store({
//   modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  state: {
    status: '',
    token: localStorage.getItem('@whey-token') || '',
    pushId: '',
    tempToken: '',
    user: {},
    notifications: {
      count: 0,
      messages: [],
    }
  },
  mutations: {
    ['AUTH_REQUEST']: (state) => {
      state.status = 'loading'
    },
    ['AUTH_SUCCESS']: (state, token) => {
      state.status = 'success'
      state.token = token
    },
    ['AUTH_ERROR']: (state) => {
      state.status = 'error'
    },
    ['AUTH_LOGOUT']: (state) => {
      state.status = ''
      state.token = ''
    },
    ['USER_DATA']: (state, data) => {
      state.user = data
    },
    ['TEMP_TOKEN']: (state, data) => {
      state.tempToken = data
    },
    ['NOTIFICATIONS']: (state, data) => {
      state.notifications = data
    },
    ['UPDATE_PUSH_ID']: (state, pushId) => {
      state.pushId = pushId;
    },
  },
  actions: {
    ['AUTH_REQUEST']: ({commit}, data) => {
      return new Promise((resolve, reject) => { // The Promise used for router redirect in login
        commit('AUTH_REQUEST')
        UserService.login(data.email, data.password)
          .then(res => {
            if (res.data.changePassword) {
              return reject({ changePassword: true, tempToken: res.data.tempToken })
            }

            const token = res.data.token
            localStorage.setItem('@whey-token', token) // store the token in localstorage

            commit('AUTH_SUCCESS', token)
            // you have your token, now log in your user :)
            resolve(res)
          })
        .catch(err => {
          commit('AUTH_ERROR', err)
          localStorage.removeItem('@whey-token') // if the request fails, remove any possible user token if possible
          reject(err)
        })
      })
    },
    ['AUTH_LOGOUT']: ({commit, state}) => {
      return new Promise((resolve) => {
        const pushId = state.pushId;
        // eslint-disable-next-line no-console
        console.log(pushId);
        // if (pushId) {
        //   UserService.logout(pushId, localStorage.getItem('@whey-token')).then(() => {})
        // }
        
        localStorage.removeItem('@whey-token') // clear your user's token from localstorage
        commit('USER_DATA', {});
        commit('AUTH_LOGOUT')
        resolve()
      })
    },
    ['USER_DATA']: ({commit, state}) => {
      return new Promise((resolve, reject) => {
        if (state.user.email) {
          return resolve(state.user);
        }

        UserService.getData()
          .then(res => {
            commit('USER_DATA', res.data);

            resolve(res.data)
          })
        .catch(err => {
          reject(err)
        })
      })
    },
    ['UPDATE_NOTIFICATIONS']: ({commit}) => {
      UserService.getData().then(res => {
        commit('NOTIFICATIONS', res.data.notifications);
      })
    },
    ['UPDATE_PUSH_ID']: ({commit}, data) => {
      commit('UPDATE_PUSH_ID', data);
    },
  },
  getters: {
    isAuthenticated: state => !!state.token,
    authStatus: state => state.status,
    userData: state => state.user,
    tempToken: state => state.tempToken,
    pushId: state => state.pushId,
  },
  strict: process.env.NODE_ENV !== 'production',
})

export default store

