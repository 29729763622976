import io from 'socket.io-client'
// import vue from '@/main'

// const socket = io('http://localhost:3080')
const socket = io('https://wheyapp.herokuapp.com/')

socket.on('connect', () => {
  // vue.$events.fire('subscribe')

  // eslint-disable-next-line no-console
  console.log('socket connected')
})

socket.on('disconnect', () => {
  // vue.$events.fire('unsubscribe')

  // eslint-disable-next-line no-console
  console.log('socket disconnected')
})

export default socket
