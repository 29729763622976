import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueMask from 'v-mask'
import VueRouter from 'vue-router'
import vco from 'v-click-outside'
import router from './router/index'
import money from 'v-money'

import "@/design/index.scss";
import VueSweetalert2 from 'vue-sweetalert2';
import store from '@/state/store'
import VueToast from 'vue-toast-notification';
// import 'vue-toast-notification/dist/theme-default.css';
import 'vue-toast-notification/dist/theme-sugar.css'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

import '@/sockets/subscribers'

import App from './App.vue'

Vue.use(VueRouter)
Vue.use(vco)

Vue.config.productionTip = false

Vue.use(Loading, {
  color: '#e0103d',
  width: 45,
  height: 45,
  canCancel: false,
})

const moment = require('moment')
require('moment/locale/pt-br')
 
Vue.use(require('vue-moment'), {
    moment
})

Vue.use(money, { precision: 2 })
Vue.use(VueToast);
Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(VueMask)
Vue.use(require('vue-chartist'))
Vue.use(VueSweetalert2);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE',
    libraries: 'places',
  },
  installComponents: true
})
Vue.component('apexchart', VueApexCharts)

const vue = new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

export default vue