import api from '@/services/api'
import axios from 'axios'

export default {
  login(email, password) {
    return api.post('/admin/auth', { email, password })
  },

  logout(pushId, token) {
    return api.post('/admin/logout', { pushId }, { headers: { 'x-token': token } })
  },

  changePassword(password, token) {
    return api.post('/admin/change-password', { password }, { headers: { 'x-token': token } })
  },

  getData() {
    return api.get('/admin')
  },

  getStats() {
    return api.get('/admin/stats')
  },

  getStoreStats() {
    return api.get('/admin/store/stats')
  },

  getUserList(term) {
    let params = '';
    if (term) {
      params = '?term=' + term;
    }

    return api.get('/users/list' + params);
  },

  getChatMessages(contactId) {
    return api.get(`chat/${contactId}`);
  },

  getChatMessagesByContact() {
    return api.get('chat/contacts');
  },

  sendChatMessage(text, toUser) {
    return api.post('chat', { text, toUser });
  },

  newMessage(userId) {
    return api.post('chat/new', { toUser: userId });
  },

  updatePushId(pushId) {
    return api.post('/admin/update-push-id', { pushId });
  },

  getAddressByZipcode(zipcode) {
    return axios.get(`https://viacep.com.br/ws/${zipcode.replace(/\D/g, '')}/json`);
  }
}