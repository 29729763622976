<script>
import appConfig from "@/app.config";

export default {
  name: "app",
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate(title) {
      title = typeof title === "function" ? title(this.$store) : title;
      return title ? `${title} | ${appConfig.title}` : appConfig.title;
    }
  },
  mounted() {
    const OneSignal = window.OneSignal || [];
    OneSignal.push(() => {
      OneSignal.init({
        appId: '9bd137fc-4e8d-4abe-90d3-768c2932a602',
        autoRegister: false,  
        persistNotification: true,
        notifyButton: {
          enable: true,
        },
      })

      OneSignal.getUserId((id) => {
        // eslint-disable-next-line no-console
        console.log('push ID', id);

        this.$store.dispatch('UPDATE_PUSH_ID', id).then(() => {});
      })
    })
  },
};
</script>

<template>
  <div id="app">
    <RouterView />
  </div>
</template>
