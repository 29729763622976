import axios from 'axios'

const axiosApi = axios.create({
//   baseURL: 'http://localhost:3080/'
  baseURL: 'https://wheyapp.herokuapp.com/'
});

axiosApi.interceptors.request.use(
  config => {
    const token = localStorage.getItem('@whey-token');
    if (token) {
      config.headers['x-token'] = token
    }

    return config
  }
)

export default axiosApi